@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  app-root a {
    color: mat.get-color-from-palette($primary-palette, 700);
  }

  app-root a:visited {
    color: mat.get-color-from-palette($primary-palette, 700);
  }

  app-root a:focus,
  app-root a:hover{
    color: mat.get-color-from-palette($primary-palette, 300);
  }
}
