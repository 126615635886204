/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: OpenSans;
  src: url(assets/font/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(assets/font/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(assets/font/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(assets/font/OpenSans-BoldItalic.ttf) format('truetype');
}

* {
  font-family: OpenSans, sans-serif;
  line-height: 1.5em;
}

body {
  margin: 0;
  padding-bottom: 4em;
}

h3 {
  font-family: OpenSans, sans-serif !important;
  margin: 0 0 6px !important;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent{
  color:#fff;
}

snack-bar-container.error {
  background: red;
  color: white;
  font-size: larger;
  white-space: pre-wrap;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-raised-button,
.mat-stroked-button {
  font-size: 12px;
  font-weight: 700;
}
